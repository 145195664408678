import "./src/styles/global.css";

/* global window, document */
const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el)
    return window.scrollTo({ top: el.offsetTop - 20, behavior: "smooth" });
  return false;
};

export const onRouteUpdate = ({ location }) => {
  console.log(location);
  if (location.hash) {
    window.setTimeout(scrollTo(location.hash), 10);
  }

  navigator.serviceWorker.register('/sw.js').then((reg) => {
    reg.update();
  });
};

export const onServiceWorkerUpdateFound = () => {
  window.location.reload(true)
};